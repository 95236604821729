import React, { useEffect } from 'react';
import './App.css';
import hive from "./hive"
import LeftMenu from "./hive/leftMenu"
import Header from "./hive/header"
import ToolTip from "./hive/tooltip"
import MobileFLoorMenu from "./hive/mobileFloorMenu"
import { useState } from "@hookstate/core"
import { R_HEADER_TOP_HEIGHT, R_HEADER_HEIGHT, R_MOBILE_HEADER_HEIGHT } from './hive/constant';
import GoBack from './hive/GoBack';
import Image from './hive/Image';
import Button from './hive/Button';
import BotMenu from './hive/BotMenu';
import MobileLogo from './hive/MobileLogo';
import Tour from './hive/Tour';
import InfoCard from './hive/InfoCard';
import Compas from './hive/Compas';
import SearchButton from './hive/SearchButton';
import Loading from './hive/Loading';
import { Fullscreen, ThreeDRotation, ZoomIn, ZoomOut } from '@material-ui/icons';
import Search from './hive/Search';
import Favorites from './hive/Favorites';
import RotateIcon from './hive/RotateIcon';
import TypeFilter from './hive/TypeFilter';

function FullScreenButton() {
  const { height, mainColor, totalWidth, isMobile } = useState(hive.state)

  const size = height.get() * (isMobile.get() ? 0.15 : 0.07);
  const totalSize = size * 2.5

  return <div
    onClick={() => {
      hive.toggleFullscreen()
    }}
    style={{
      position: "absolute",
      backfaceVisibility: "hidden",
      height: size,
      width: totalSize,
      marginTop: height.get() - size * (isMobile.get() ? 2.5 : 1.1),
      marginLeft: totalWidth.get() - totalSize * 1,
      zIndex: 3,
      color: `#${mainColor.get()}`,
      cursor: "pointer",
      display: "flex",
    }}>
    <div style={{
      fontSize: height.get() * 0.03 + "px",
      lineHeight: height.get() * 0.025 + "px",
      fontWeight: "bold",
      marginTop: height.get() * 0.01
    }}>
      Plein <br /> Ecran
    </div>
    <div>
      <Fullscreen style={{
        width: size + "px",
        height: size + "px",
      }}></Fullscreen>
    </div>
  </div>
}

function ZoomButton() {
  const { leftMenu, height, zoom, mainColor } = useState(hive.state);

  return <>{
    true && <div style={{

      position: "absolute",
      // zIndex: 45,
      width: height.get() * 0.05,
      // height: height.get() * 0.15,
      top: (height.get() * 0.90) - (height.get() * 0.15),
      left: height.get() * 0.05,
      backfaceVisibility: "hidden",
      background: "white",
      color: `#${mainColor.get()}`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: height.get() * 0.007,
      paddingTop: height.get() * 0.009,
      borderRadius: height.get() * 0.008,
    }}>
      <div style={{
        opacity: zoom.get() < 1.5 ? 1 : 0.2,
        cursor: zoom.get() < 1.5 ? "pointer" : "initial",
      }}
        onClick={() => {
          if (zoom.get() < 1.5) {
            zoom.set(e => e + 0.1);
          }
        }}
      >
        <ZoomIn style={{
          width: height.get() * 0.05 + "px",
          height: height.get() * 0.05 + "px",
        }}></ZoomIn>
      </div>
      <div style={{
        opacity: zoom.get() > 1 ? 1 : 0.2,
        cursor: zoom.get() > 1 ? "pointer" : "initial",
      }}
        onClick={() => {
          if (zoom.get() > 1) {
            zoom.set(e => e - 0.1);
          }
        }}>
        <ZoomOut style={{
          width: height.get() * 0.05 + "px",
          height: height.get() * 0.05 + "px",
        }}></ZoomOut>
      </div>
    </div>
  }</>
}

function App() {
  const { isMobile, image, botMenu, back, tour, infoCard, compas, leftMenu, searchButton, search, favorites, favoriteList, nbrLots, height, typeFilter, activeScene, zoom } = useState(hive.state)

  useEffect(() => {
    hive.vision.setContainer(document.querySelector("#visionContainer") as HTMLElement)
    hive.init()
    return () => { }
  }, [])

  return (
    <div className="App">
      {!isMobile.get() && <Header></Header>}
      {(isMobile.get() && !back.get()) && <MobileLogo></MobileLogo>}
      {back.get() && <GoBack></GoBack>}
      {typeFilter.get() &&
        <div style={{
          position: "absolute",
          zIndex: 4,
          display: "flex",
          opacity: "0.8",
          fontSize: height.get() * 0.028 + "px",
          top: isMobile.get() ? height.get() * 0.92 : height.get() * 1.01,
          left: height.get() * 0.04,
          backfaceVisibility: "hidden",
        }}>
          <TypeFilter></TypeFilter>
        </div>
      }
      {image.get() && <Image></Image>}
      {botMenu.get() && <BotMenu></BotMenu>}
      {infoCard.get() && <InfoCard></InfoCard>}
      {tour.get() && <Tour></Tour>}
      {isMobile.get() && leftMenu.get().length > 0 && <>
        <Button
          onClick={() => { hive.toggleFloorMenu() }}
          topRatio={0.16}
          icon={"layer"}
          text={'Etages'}
        >
        </Button>

        {nbrLots.get() > 0 && <>
          <Button
            onClick={() => { hive.action("setScene", "search") }}
            topRatio={0.32}
            icon={"search"}
            text={'Filtrer'}
          >
          </Button>
          {favoriteList.length > 0 &&
            <Button
              onClick={() => { hive.action("setScene", "favorites") }}
              topRatio={0.48}
              icon={"favorite"}
              text={'Favoris'}
            >
            </Button>
          }
        </>}

      </>
      }
      {!isMobile.get() && <FullScreenButton></FullScreenButton>}
      {compas.get() && <Compas></Compas>}
      {(nbrLots.get() > 0 && searchButton.get() && !isMobile.get()) && <SearchButton></SearchButton>}
      {search.get() && <Search></Search>}
      {favorites.get() && <Favorites></Favorites>}
      <RotateIcon></RotateIcon>
      <Loading></Loading>
      <div style={{
        width: "100vw",
        // height: isMobile.get() ? "100vh" : `calc(100vh - (100vh * ${R_HEADER_HEIGHT}))`,
        height: "100vh",
        overflow: "hidden",
      }}>
        <div style={{
          width: "100%",
          height: "100%",
          transform: `scale(${zoom.get()})`,
          transformOrigin: `50vw ${height.get() * 0.5}px`,
        }}>
          <div id="visionContainer" style={{
            width: "100%",
            height: "100%",
            // height: isMobile.get() ? "100vh" : `calc(100vh - (100vh * ${R_HEADER_HEIGHT}))`,
          }}>
          </div>
        </div>
      </div>
      <ZoomButton></ZoomButton>
      {isMobile.get() && leftMenu.get().length > 0 && <MobileFLoorMenu></MobileFLoorMenu>}
      {!isMobile.get() && leftMenu.get().length > 0 && <LeftMenu></LeftMenu>}
      <ToolTip></ToolTip>
    </div>
  );
}

export default App;
