import { createState, useState } from "@hookstate/core"
import vision, { Poi, sleep } from "../core/vision"
import { id, loadMode } from "../../../project.json"
import { Config } from "../core/config.interface";
import { project, LeftMenu, Scene, Layouts, Layout, Project } from './project/project'
import { Subject } from "rxjs";
import { none } from '@hookstate/core'
declare var fullscreenElement: any


declare const PouchDB: any;

enum Env {
    Prod,
    Preprod
}

class Hive {
    state = createState({
        leftMenu: [],
        totalWidth: 1920,
        width: 1920,
        height: 1080,
        activeScene: project.defaultScene,
        activeLayout: "",
        activeOrbit: "",
        image: undefined,
        back: undefined,
        backColor: "",
        tour: undefined,
        infoCard: undefined,
        botMenu: [],
        mainColor: project.mainColor,
        isMobile: false,
        floorMenuOpened: false,
        isTouch: false,
        compas: false,
        compasStart: 0,
        loading: true,
        searchButton: false,
        typeFilter: false,
        favoriteList: [],
        searchResult: [],
        search: false,
        favorites: false,
        favoriteLeft: "",
        favoriteRight: "",
        nbrLots: 0,
        showRotate: false,
        isSurfaceAllFilter: false,
        zoom: 1,
    } as {
        leftMenu: LeftMenu[],
        totalWidth: number,
        width: number,
        height: number,
        activeScene: string,
        activeOrbit: string,
        mainColor: string,
        isMobile: boolean
        floorMenuOpened: boolean,
        // typeColors: number[][],
        image: string | undefined,
        tour: string | undefined,
        back: string | undefined
        backColor: string
        isTouch: boolean,
        botMenu: any[],
        infoCard: any,
        activeLayout: string,
        compas: boolean,
        compasStart: number,
        loading: boolean,
        searchButton: boolean,
        favoriteList: any[],
        searchResult: any[],
        search: boolean
        favorites: boolean,
        favoriteLeft: string,
        favoriteRight: string,
        nbrLots: number,
        showRotate: boolean,
        typeFilter: boolean,
        isSurfaceAllFilter: boolean,
        zoom: number,
    })
    angle = createState(0)

    priceSlider = createState({
        widthRatio: 0.4,
        heightRatio: 0.1,
        domain: [50_000, 500_000],
        values: [70_000, 200_000],
        step: 5000,
        text: "€"
    })

    surfaceSlider = createState({
        widthRatio: 0.4,
        heightRatio: 0.1,
        domain: [7, 500],
        values: [7, 500],
        step: 1,
        text: "m2"
    })

    surfaceAllSlider = createState({
        widthRatio: 0.4,
        heightRatio: 0.1,
        domain: [7, 500],
        values: [7, 500],
        step: 1,
        text: "m2"
    })


    searchFilter = createState(project.filterCheck as any[])
    additonalSearchFilter = createState(project.additionalFilterCheck as any[])

    overState = createState({
        x: 0,
        y: 0,
        poi: undefined,
        lot: undefined,
    } as {
        x: number,
        y: number,
        poi: Poi | undefined,
        lot: any
    })
    vision = new vision({
        loadMode,
        width: 1920,
        height: 1080
    })
    config!: Config;
    lots: any;
    pois: any;
    overLot: any;
    db: any;
    env = Env.Preprod
    selectedScene = project.defaultScene

    constructor() {
        this.env = window.location.href.indexOf(id) === -1 ? Env.Preprod : Env.Prod
        this.db = new PouchDB(`https://db.hive-project.fr/${id}${this.env === Env.Prod ? '' : ''}`, {
            fetch: (url: any, opts: any) => {
                opts.credentials = 'include';
                return PouchDB.fetch(url, opts);
            },
            skip_setup: true
        });
    }

    getSceneById(id: string) {
        return project.scenes.find(s => s.id === id) as Scene;
    }

    async fadeIn(poi: Poi) {
        for (let i = 0; i < 5; i++) {
            poi.color[3] += 0.05;
            this.vision.applyColorPoi()
            await new Promise(r => requestAnimationFrame(r))
        }
    }

    async fadeOut(poi: Poi) {
        for (let i = 0; i < 5; i++) {
            poi.color[3] += -0.05;
            this.vision.applyColorPoi()
            await new Promise(r => requestAnimationFrame(r))
        }
    }

    async fadeInValue(poi: Poi, value: number) {
        for (let i = 0; poi.color[3] < value; i++) {
            poi.color[3] += 0.05;
            if (poi.color[3] > value) poi.color[3] = value;
            this.vision.applyColorPoi()
            await new Promise(r => requestAnimationFrame(r))
        }
    }

    async fadeOutZero(poi: Poi) {
        for (let i = 0; poi.color[3] > 0; i++) {
            poi.color[3] += -0.05;
            if (poi.color[3] < 0) poi.color[3] = 0;
            this.vision.applyColorPoi()
            await new Promise(r => requestAnimationFrame(r))
        }
    }


    poiOvered: Poi | undefined;
    poiOverevent = new Subject<{ poi: Poi | undefined, clientX: number, clientY: number }>()
    onPoiOver(e: { poi: Poi | undefined, clientX: number, clientY: number }) {
        this.poiOverevent.next(e)
        const poi = e.poi;
        if (poi && this.lots[poi.id].active && (this.lots[poi.id].enabled)) {
            document.body.style.cursor = "pointer"
        } else {
            document.body.style.cursor = "initial"
        }
        this.overState.x.set(e.clientX)
        this.overState.y.set(e.clientY)
        if (poi && this.lots[poi.id].active && this.lots[poi.id].enabled) {
            if (poi !== this.poiOvered) {
                this.overState.poi.set(poi)
                this.overState.lot.set(this.lots[poi.id])

                this.fadeIn(poi)
                if (this.poiOvered) {
                    this.fadeOut(this.poiOvered)
                }
                this.poiOvered = poi;
            }
        } else {
            if (this.poiOvered) {
                this.fadeOut(this.poiOvered)
                this.poiOvered = undefined;
                this.overState.poi.set(undefined)
                this.overState.lot.set(undefined)
            }
        }
    }

    toggleFavorite(id: string) {
        const index = this.state.favoriteList.get().indexOf(id)
        if (index === -1) {
            this.state.favoriteList.merge([id])
        } else {
            this.state.favoriteList[index].set(none)
        }
        localStorage.setItem("favorites", JSON.stringify(this.state.favoriteList.get()))
    }

    onClickPoi(poi: Poi) {
        const lot = this.lots[poi.id]
        document.body.style.cursor = "initial"
        if (lot && lot.active) {
            this.action(lot.action, lot.target)
        }
    }

    handleTouch() {
        this.state.isTouch.set(this.vision.isTouch)
        this.vision.events.mouseMove.subscribe(e => {
            const poi = e.poi
            this.onPoiOver(e)
        })

        this.vision.events.startRotating.subscribe(e => {
            this.onPoiOver({ poi: undefined, clientX: this.overState.x.get(), clientY: this.overState.y.get() })
        })

        this.vision.events.tap.subscribe((e) => {
            const poi = e.poi

            if (this.vision.isTouch) {
                if (poi) {
                    this.onPoiOver(e)
                } else {
                    this.onPoiOver({ poi: undefined, clientX: this.overState.x.get(), clientY: this.overState.y.get() })
                }
            } else {
                if (poi) {
                    this.onClickPoi(poi)
                }
            }
        })
    }

    goBack() {
        if (this.state.back.get() === "layout") {
            this.layoutHistory.pop()
            this.setLayout(this.layoutHistory[this.layoutHistory.length - 1])
            this.layoutHistory.pop()
        } else if (this.state.back.get() === "scene") {
            this.scenesHistory.pop()
            this.setScene(this.scenesHistory[this.scenesHistory.length - 1])
            this.scenesHistory.pop()
        }
    }

    async setOrbit(orbitId: string) {
        console.log(orbitId);
        await this.vision.setScene(orbitId)
        this.state.compasStart.set((this.config.pictures.find(p => p.name === orbitId) as any).compas)
        this.state.activeOrbit.set(orbitId)
    }

    lastLayoutId = ""
    activeLayoutId = ""
    layoutHistory: string[] = [];
    async setLayout(layoutId: string) {
        const layout = this.setTemplate(project.layouts[layoutId], this.getSceneById(this.activeSceneId)) as Layout;
        if (layout.orbit) {
            await this.setOrbit(layout.orbit)
        } else {
            this.state.activeOrbit.set("")
        }
        this.lastLayoutId = this.activeLayoutId
        this.activeLayoutId = layoutId
        this.state.activeLayout.set(layoutId)
        this.state.leftMenu.set(layout.leftMenu ? layout.leftMenu : [])
        this.state.image.set(layout.image)
        this.state.botMenu.set(layout.botMenu ? layout.botMenu : [])
        this.state.back.set(layout.back)
        this.state.tour.set(layout.tour)
        this.state.infoCard.set(Object.values(this.lots).find((e: any) => e.id === layout.infoCard))
        this.state.compas.set(layout.compas)
        this.state.searchButton.set(layout.searchButton)
        this.state.search.set(layout.search)
        this.state.backColor.set(layout.backColor ? layout.backColor : project.mainColor)
        this.state.favorites.set(layout.favorites)
        this.state.typeFilter.set(layout.typeFilter)
        this.layoutHistory.push(layoutId)
    }

    lastSceneId = ""
    activeSceneId = ""
    scenesHistory: string[] = [];
    setScene(scene: string) {
        this.lastSceneId = this.activeSceneId
        const sceneTarget = this.getSceneById(scene)
        this.activeSceneId = scene;
        this.onPoiOver({ poi: undefined, clientX: this.overState.x.get(), clientY: this.overState.y.get() })
        this.setLayout(sceneTarget.layout)
        this.state.activeScene.set(sceneTarget.id)
        this.scenesHistory.push(scene)
    }

    setPoi(target: string) {
        console.log(target);
    }

    toggleFloorMenu() {
        this.onPoiOver({ poi: undefined, clientX: this.overState.x.get(), clientY: this.overState.y.get() })
        this.state.floorMenuOpened.set(e => !e);
    }

    actualAction = ""
    actualTarget = ""
    lastCtion = ""
    lastTarget = ""
    public action(action: string, target: string) {
        if (action === "setScene") {
            this.setScene(target)
        } else if (action === "setLayout") {
            this.setLayout(target)
        } else if (action === "setOrbit") {
            this.setOrbit(target)
        }
        this.lastCtion = this.actualAction
        this.lastTarget = this.lastTarget
        this.actualAction = action
        this.actualTarget = target
    }

    private setTemplate(obj: any, objFill: any) {
        const replaceAll = (string: string, search: string, replace: string) => {
            return string.split(search).join(replace);
        }
        let str = JSON.stringify(obj)
        const sp = str.split("%")
        for (let key of sp.filter((e, i) => i % 2 === 1)) {
            str = replaceAll(str, `%${key}%`, objFill[key])
        }
        const res = JSON.parse(str)
        return res
    }

    getSearchFilterFromLot(lot: any) {
        const searchFilter = this.searchFilter.get() as any[]
        for (let search of searchFilter) {
            if (lot[search.key] && lot[search.key] === search.value) {
                return JSON.parse(JSON.stringify(search))
            }
        }
    }

    filterNeedUpdate = true
    private async listenFilterUpdate() {
        while (true) {
            if (this.filterNeedUpdate) {
                this.filterNeedUpdate = false;
                this.computeFilter()
            }
            await new Promise(r => setTimeout(r, 200))
        }
    }

    private computeFilter() {
        const searchFilter = this.searchFilter.get() as any[]
        const additonalSearchFilter = this.additonalSearchFilter.get() as any[]
        this.state.searchResult.set((Object.values(this.lots) as any[]).filter(lot => {
            const ret = (res: boolean) => {
                lot.enabled = res;
                return res
            }

            for (let search of additonalSearchFilter) {
                if (lot[search.key] && lot[search.key] === search.value) {
                    if (!search.active) {
                        return ret(false);
                    }
                }
            }

            if (this.state.isSurfaceAllFilter.get()) {
                const surfaceAll = parseFloat(lot.surface_all.replace(",", "."))
                if (surfaceAll < this.surfaceAllSlider.values[0].get() ||
                    surfaceAll > this.surfaceAllSlider.values[1].get()
                ) {
                    return ret(false)
                }
            }

            if (searchFilter.find(e => e.active)) {
                for (let search of searchFilter) {
                    if (lot[search.key] && lot[search.key] === search.value) {
                        if (!search.active) {
                            return ret(false);
                        }
                    }
                }
            }
            if (lot.active === false) {
                return ret(false)
            }
            const surface = parseFloat(lot.surface.replace(",", "."))
            const price = parseFloat(lot.price)
            if (surface < this.surfaceSlider.values[0].get() ||
                surface > this.surfaceSlider.values[1].get() ||
                price < this.priceSlider.values[0].get() ||
                price > this.priceSlider.values[1].get()
            ) {
                return ret(false)
            }
            return ret(true);
        }))
        for (let poi of Object.values(this.pois) as any) {
            const alpha = project.baseBright
            if (!this.lots[poi.id].enabled) {
                this.fadeOutZero(poi)
                // poi.color[3] = 0
            } else {
                this.fadeInValue(poi, project.baseBright)
                // poi.color[3] = alpha
            }
        }
        // this.vision.applyColorPoi()
    }

    applyFilter() {
        this.filterNeedUpdate = true;
    }


    private async getData() {
        await Promise.all([
            (async () => { this.config = await this.db.get("config") as Config })(),
            (async () => {
                const lots = (await this.db.get("lots") as any).data
                for (let lot of Object.entries(lots)) {
                    lots[lot[0]] = this.setTemplate(lot[1], lot[1])
                    lots[lot[0]].enabled = true
                    lots[lot[0]].background = this.getSearchFilterFromLot(lots[lot[0]]).background
                    lots[lot[0]].enabled = true
                    if (lots[lot[0]].surface_all) {
                        this.state.isSurfaceAllFilter.set(true)
                    } else {
                        lots[lot[0]].surface_all = lots[lot[0]].surface
                    }
                }
                for (let lot of Object.values(lots)) {
                    project.scenes.push(lot as Scene)
                }
                this.state.nbrLots.set(Object.values(lots).filter((e: any) => e.active).length)
                this.lots = lots
                console.log(this.lots);
            })()
        ])
        this.state.searchResult.set(Object.values(this.lots))
        const pois = this.vision.setConfig(this.config)
        this.pois = pois;
        for (let poi of Object.values(pois)) {
            const alpha = project.baseBright
            const color = this.getSearchFilterFromLot(this.lots[poi.id]).background
            poi.color = [color[0] / 256, color[1] / 256, color[2] / 256, alpha]
            if (!this.lots[poi.id].active) {
                poi.color[3] = 0
            }
        }

        let maxSurface = 0;
        let minSurface = 999999999
        let maxSurfaceAll = 0;
        let minSurfaceAll = 999999999
        let maxPrice = 0;
        let minPrice = 9999999999

        for (let lot of (Object.values(this.lots) as any[])
            // .filter(e => !e.hide_lot)
        ) {
            lot = lot as any;
            if (lot.surface > maxSurface) maxSurface = parseFloat(lot.surface.replace(",", "."))
            if (lot.surface < minSurface) minSurface = parseFloat(lot.surface.replace(",", "."))

            if (lot.surface_all > maxSurfaceAll) maxSurfaceAll = parseFloat(lot.surface_all.replace(",", "."))
            if (lot.surface_all < minSurfaceAll) minSurfaceAll = parseFloat(lot.surface_all.replace(",", "."))

            if (lot.price > maxPrice) maxPrice = parseInt(lot.price)
            if (lot.price < minPrice) minPrice = parseInt(lot.price)
        }
        maxPrice = Math.floor(maxPrice) + 5000
        minPrice = Math.floor(minPrice)
        this.priceSlider.values.set([minPrice, maxPrice])
        this.priceSlider.domain.set([minPrice, maxPrice])

        maxSurface = Math.floor(maxSurface) + 1
        minSurface = Math.floor(minSurface)
        this.surfaceSlider.values.set([minSurface, maxSurface])
        this.surfaceSlider.domain.set([minSurface, maxSurface])

        maxSurfaceAll = Math.floor(maxSurfaceAll) + 1
        minSurfaceAll = Math.floor(minSurfaceAll)
        this.surfaceAllSlider.values.set([minSurfaceAll, maxSurfaceAll])
        this.surfaceAllSlider.domain.set([minSurfaceAll, maxSurfaceAll])

        const fav = JSON.parse(localStorage.getItem("favorites") as string)
        if (fav) {
            this.state.favoriteList.set(JSON.parse(localStorage.getItem("favorites") as string))
        }
    }

    isFullScreen = false;
    async toggleFullscreen() {
        // eslint-disable-next-line no-restricted-globals
        const elem = document.documentElement as any
        if (!this.isFullScreen) {
            if (elem.requestFullscreen) {
                await elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) { /* Firefox */
                await elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                await elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE/Edge */
                await elem.msRequestFullscreen();
            }
            this.isFullScreen = true
        } else {
            if ((document as any).exitFullscreen) {
                await (document as any).exitFullscreen();
            } else if ((document as any).mozCancelFullScreen) { /* Firefox */
                await (document as any).mozCancelFullScreen();
            } else if ((document as any).webkitExitFullscreen) { /* Chrome, Safari and Opera */
                await (document as any).webkitExitFullscreen();
            } else if ((document as any).msExitFullscreen) { /* IE/Edge */
                await (document as any).msExitFullscreen();
            }
            this.isFullScreen = false;
        }
        await sleep(100)
        this.vision.handleSize()
    }

    iconRotateShowed = false;
    async init() {
        this.vision.events.resize.subscribe((size) => {
            this.state.width.set(size.width)
            this.state.height.set(size.height)
            this.state.totalWidth.set(size.totalWidth)
            this.state.isMobile.set(size.width < 800 ? true : false)
        })
        await this.getData()
        this.handleTouch()
        this.vision.events.loading.subscribe(isLoading => {
            this.state.loading.set(isLoading)

            if (!isLoading && !this.iconRotateShowed) {
                this.state.showRotate.set(true)
                this.iconRotateShowed = true;
                setTimeout(() => {
                    this.state.showRotate.set(false)
                }, 5000)
            }
        })
        this.vision.events.rotate.subscribe((angle) => {
            this.state.showRotate.set(false)
            if (this.vision.activeScene.frames.length > 1) {
                this.angle.set(angle)
            }
        })
        this.action("setScene", project.defaultScene)
        setTimeout(() => {
            this.listenFilterUpdate()
            // this.applyFilter()
        })
    }
}

export default new Hive()